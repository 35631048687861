let BASEURL;
if (
  window.location.hostname === "settlrs.com" ||
  window.location.hostname === "www.settlrs.com"
) {
  // Load production environment variables
  BASEURL = process.env.REACT_APP_API_ENDPOINT_PROD;
} else {
  // Load development environment variables
  BASEURL = process.env.REACT_APP_API_ENDPOINT_DEV;
}

export { BASEURL };
